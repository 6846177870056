<template>
  <v-container id="strategy" fluid tag="section">
    <v-skeleton-loader v-if="firstLoad" :loading="loading" type="table"></v-skeleton-loader>

    <div v-else>
      <v-row>
        <v-col cols="8" md="6" lg="4">
          <v-row align-content-start>
            <v-btn :class="'ml-3 ' + btnClass">Send</v-btn>
            <v-btn :disabled="disabled" :class="'ml-2 ' + btnClass">Cancel</v-btn>
            <v-btn :class="'ml-2 ' + btnClass">Refresh</v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table dense width="100%" v-model="selected" :single-select="true" show-select :headers="headers"
            :items="items" class="elevation-1">
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import {
  getOrders,
  cancelOrder,
  getMarkets,
  subscribe,
  sendOrder,
  myMixins,
} from "@/api";

export default {
  mixins: [myMixins],
  name: "OrderPanel",
  components: {},
  mounted: async function () {
    const self = this;
    try {
      self.marketInfo = await getMarkets();

      self.gatewayLst = self.marketInfo.gatewayLst;
      self.marketMap = self.marketInfo.marketMap;

      self.markets = [
        "LRC-ETH",
        "LRC-USDT",
        "LRC-USDC",
        "ETH-USDT",
        "ETH-USDC",
      ];

      self.gatewayLst.forEach(async (gateway_name) => {
        if (self.marketInfo.marketMap[gateway_name].exchange === "LOOPRING36") {
          let resp = await subscribe(gateway_name, self.markets.join(","));
        }

        self.delayRefreshOrder(3000);
      });
      self.market = self.markets[0];

      if (self.gatewayLst.length > 0) {
        self.gateway_name = self.gatewayLst[0];
      }

      self.timer = setInterval(() => {
        self.delayRefreshOrder();
      }, 30000);
    } catch (error) {
      self.logout();
    }
  },

  watch: {
    selected(val) {
      this.disabled = !(val !== undefined && val !== null && val.length > 0);
    },
  },

  computed: {
    color() {
      return this.$vuetify.theme.dark ? "white" : "black";
    },
  },

  data() {
    return {
      firstLoad: true,
      loading: true,
      disabled: true,
      selected: [],
      btnClass: "ma-0 white--text",
      singleSelect: false,
      headers: [
        { text: "Account", value: "gateway_name" },
        { text: "Market", value: "market" },
        { text: "Status", value: "status" },
        { text: "Type", value: "type" },
        { text: "Direction", value: "direction" },
        { text: "Price", value: "price" },
        { text: "Volume", value: "volume" },
        { text: "clientOrderId", value: "clientOrderId" },
        { text: "hash", value: "hash" },
      ],
      markets: [],
      directions: ["BUY", "SELL"],
      gatewayLst: [],
      marketMap: {},
      items: [],
    };
  },

  methods: {
    delayRefreshOrder(timeout = 0) {
      const self = this;
      setTimeout(() => {
        self.refreshOrders();
      }, timeout);
    },

    refreshOrders() {
      const self = this;

      self.loading = true;

      console.log("start loading!");

      getOrders()
        .then((orders) => {
          if (orders) {
            let key = 0;
            self.items = orders.map((item) => {
              item._showDetails = true;
              item.key = key++;
              return item;
            });
          }

          if (this.firstLoad) {
            this.firstLoad = false;
          }
          this.loading = false;
          console.log("finish loading!");
        })
        .catch((reason) => {
          console.error(reason);
          self.logout();
        });
    },
  },
};
</script>
